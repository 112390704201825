export const SUGGESTED_QUESTIONS = [
	{
		slug: "dates",
		type: "MULTIPLE",
		text: "What date(s) work best?",
		options: [],
		addOptionAllowed: true,
		allowMultipleAnswers: true
	},
	{
		slug: "destination",
		type: "MULTIPLE",
		text: "Where would you like to go?",
		options: [],
		addOptionAllowed: true,
		allowMultipleAnswers: true
	},
	{
		slug: "budget",
		type: "MULTIPLE",
		text: "What's your budget style?",
		options: [
			"$ - I'm on a strict budget",
			"$$ - I'm on a flexible budget",
			"$$$ - I'm treating myself",
			"$$$$ - What budget?"
		],
		addOptionAllowed: false,
		allowMultipleAnswers: false
	},
	{
		slug: "trip",
		type: "MULTIPLE",
		text: "What vibes are you looking for?",
		options: [
			"Rest & Relaxation 😌",
			"Let's party 🥳",
			"Sightseeing & Culture 🗽",
			"Outdoor Adventures 🏞️ "
		],
		addOptionAllowed: false,
		allowMultipleAnswers: true
	}
];

export const OTHER_QUESTIONS = [
	{
		slug: "lodging",
		type: "MULTIPLE",
		text: "Do you Prefer Airbnb or Hotel?",
		options: ["Airbnb", "Hotel", "No preference", "I'll find my own place"],
		addOptionAllowed: false,
		allowMultipleAnswers: false
	},
	{
		slug: "room",
		type: "MULTIPLE",
		text: "Are you open to sharing a room?",
		options: [
			"I'll share a room, but not a bed",
			"I'm willing to share a bed",
			"I want my own room"
		],
		addOptionAllowed: false,
		allowMultipleAnswers: false
	},
	{
		slug: "pets",
		type: "MULTIPLE",
		text: "Do you want to bring pet(s)?",
		options: ["Yes", "No"],
		addOptionAllowed: false,
		allowMultipleAnswers: false
	},
	{
		slug: "partner",
		type: "MULTIPLE",
		text: "Are you bringing a partner?",
		options: ["Yes", "No"],
		addOptionAllowed: false,
		allowMultipleAnswers: false
	},
	{
		slug: "kids",
		type: "MULTIPLE",
		text: "Are you bringing kids?",
		options: ["Yes", "No"],
		addOptionAllowed: false,
		allowMultipleAnswers: false
	}
];
